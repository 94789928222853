import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import {
  closeSearch as closeSearchAction,
  openSearch as openSearchAction,
  toggleMobileNavIsOpen,
} from '@squareup/dex-data-shared-main-data-slices';
import {
  GlobalSearchEngine,
  ISearchEngine,
  Search,
  SearchDomain,
  SearchResult,
} from '@squareup/dex-search';
import { useSharedDispatch } from '@squareup/dex-store-access-shared-main-dispatch';
import {
  useSelectMobileNavState,
  useSelectSearchState,
} from '@squareup/dex-store-access-shared-main-selectors';
import { UserInfo } from '@squareup/dex-types-shared-developer-data-api';
import { ChildrenProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import { Header, Link } from '@squareup/dex-ui';
import { useDecision } from '@squareup/dex-ui-shared-feature-detection';
import {
  MobileNavShifter,
  MobileNavShifterRenderProps,
} from '@squareup/dex-ui-shared-mobile-nav';
import {
  publishStateAction,
  publishUnhandledError,
} from '@squareup/dex-utils-application-behavior-events';
import { getEnvironmentName } from '@squareup/dex-utils-environment';
import clsx from 'clsx';
import React, { FC, FunctionComponent, useCallback, useMemo } from 'react';

interface HeaderContainerProps {
  disableHamburgerMenu?: boolean | undefined;
  slim?: boolean | undefined;
  searchDomain: SearchDomain;
  searchEngine?: ISearchEngine;
  hideSearch?: boolean;
}

// This is a simple wrapper for our Link component. It has some sane defaults, and can be swapped
// with a normal <a> tag as needed.
const LinkWrapper: FC<{ href: string; trackingId: string } & ChildrenProp> = ({
  href,
  trackingId,
  children,
}) => {
  return (
    <Link passHref omitAnchor={true} trackingId={trackingId} href={href}>
      {children}
    </Link>
  );
};

const HeaderContainer = React.memo(
  ({
    className,
    disableHamburgerMenu = false,
    slim = false,
    searchDomain,
    searchEngine,
    hideSearch,
  }: HeaderContainerProps & NullableClassName) => {
    const { isOpen: isMobileNavOpen } = useSelectMobileNavState();
    const { isOpen: isSearchOpen } = useSelectSearchState();

    const searchEngineMemo = useMemo(() => {
      if (searchEngine) {
        return searchEngine;
      }

      return new GlobalSearchEngine(getEnvironmentName());
    }, [searchEngine]);

    const dispatch = useSharedDispatch();

    const openSearch = useCallback(() => {
      dispatch(openSearchAction());
      publishStateAction({
        action: 'search-activation',
        onIdentifier: 'search-toggle-on',
      });
    }, [dispatch]);

    const closeSearch = useCallback(() => {
      dispatch(closeSearchAction());
      publishStateAction({
        action: 'search-activation',
        onIdentifier: 'search-toggle-off',
      });
    }, [dispatch]);

    const onEngineQuery = useCallback(
      (query: string, results: SearchResult[], filters: string[]) => {
        publishStateAction({
          action: 'search-request',
          onIdentifier: 'search',
          status: 'success',
          extra: JSON.stringify({
            query,
            numResults: results.length,
            filters,
          }),
        });
      },
      []
    );

    const { data } = getDeveloperApi().useGetUserInfoQuery();

    const onMobileNavTriggerClicked = useCallback(() => {
      dispatch(toggleMobileNavIsOpen());
    }, [dispatch]);

    const signedIn = isSignedIn(data);

    const {
      decision: { enabled: showMpSdkLinks },
    } = useDecision('mobile_payments_sdk_docs_enabled');

    const searchNode = useMemo(() => {
      return (
        <Search
          isOpen={isSearchOpen}
          searchEngine={searchEngineMemo}
          onCloseAction={closeSearch}
          domain={searchDomain}
          LinkComponent={LinkWrapper}
          isSignedIn={signedIn}
          errorLogger={publishUnhandledError}
          onEngineQuery={onEngineQuery}
        ></Search>
      );
    }, [
      closeSearch,
      isSearchOpen,
      onEngineQuery,
      searchDomain,
      searchEngineMemo,
      signedIn,
    ]);

    const header = useMemo(() => {
      const renderHeader: FunctionComponent<MobileNavShifterRenderProps> = ({
        className: mobileShifterClassName,
      }) => {
        return (
          <Header
            className={clsx(className, mobileShifterClassName)}
            merchantName={getMerchantName(data)}
            employeeName={getEmployeeName(data)}
            onSearchClicked={openSearch}
            isSignedIn={signedIn}
            showSwitchBusiness={showSwitchBusiness(data)}
            onHamburgerMenuClicked={onMobileNavTriggerClicked}
            displaySearch={!hideSearch && Boolean(searchEngineMemo.initialized)}
            useHamburgerMenu={!disableHamburgerMenu}
            shouldNavHandleSearchKeyPress={true}
            showSearchBarHotkey={true}
            slim={slim}
            showMobilePaymentsSDKLinks={showMpSdkLinks}
          />
        );
      };

      return (
        <MobileNavShifter
          render={renderHeader}
          isMobileNavOpen={isMobileNavOpen}
          showHamburgerOnTablet={searchDomain === 'docs'}
        ></MobileNavShifter>
      );
    }, [
      isMobileNavOpen,
      searchDomain,
      className,
      data,
      openSearch,
      signedIn,
      onMobileNavTriggerClicked,
      hideSearch,
      searchEngineMemo.initialized,
      disableHamburgerMenu,
      slim,
      showMpSdkLinks,
    ]);

    return (
      <>
        {header}
        {searchNode}
      </>
    );
  }
);

HeaderContainer.displayName = 'HeaderContainer';

function getEmployeeName(data: UserInfo | undefined) {
  const firstName = data?.person?.first_name || '';
  const lastName = data?.person?.last_name || '';
  return `${firstName} ${lastName}`.trim();
}

function isSignedIn(data: UserInfo | undefined) {
  return Boolean(data?.merchant);
}

function getMerchantName(data: UserInfo | undefined) {
  return data?.merchant?.name ?? '';
}

function showSwitchBusiness(data: UserInfo | undefined) {
  const hasMultipleTokens =
    (data?.person?.production_merchant_tokens?.length ?? 0) > 1;
  return hasMultipleTokens;
}

export { HeaderContainer };
